@import url('https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap');

* {
  color: #8d8d8d;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Playfair Display', serif !important;
}

a {
  text-decoration: none;
  color: #8d8d8d;
}

a:hover {
  color: #8d8d8d;
  cursor: pointer !important;
}

.btn-outline-dark {
  color: #8d8d8d;
  border: 1px solid #8d8d8d;
  background: transparent;
}

.btn-outline-dark:hover span {
  color: #fff;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #8d8d8d;
  border: 1px solid #8d8d8d;
}

.button-appointment-wa {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 99;
}

.button-appointment-wa a {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 10px;
  width: 80px;
  height: 80px;
  font-size: 11px;
  border-radius: 50%;
}

.button-appointment-wa .appointment {
  background-color: #c15799;
  color: #fff;
}

.button-appointment-wa .wa {
  background-color: #fff;
}

.button-appointment-wa .wa img {
  width: 40%;
}

.button-appointment-wa a .icon {
  font-size: 30px;
}

.button-to-top {
  position: fixed;
  z-index: 999;
  bottom: 20px;
  left: 20px;
  border: none;
  background-color: transparent;
}

.button-to-top .icon {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #fff;
  padding: 15px;
}

.btn-see-more {
  font-size: 18px;
  text-decoration: none;
  color: #666 !important;
}

.slick-slide {
  height: auto;
}

.slick-track {
  display: flex !important;
}

.btn-see-more svg {
  opacity: 0;
  transition: 0.3s;
  margin-left: 10px;
}

.btn-see-more:hover svg {
  opacity: 1;
}

.bg-light {
  background-color: #fff !important;
}

nav.navbar.active,
nav.navbar {
  transition: 0.7s;
}

nav.navbar .container {
  position: relative;
}

nav.navbar .burger {
  position: absolute;
  right: 0;
  top: 20px;
  z-index: 999;
  background-color: transparent;
  border: none;
}

nav.navbar:hover {
  transition: 0.3s;
}

nav.navbar.active,
nav.navbar:hover {
  background-color: #fff;
  transition: 0.3s;
}

nav.navbar ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

nav.navbar.active ul.not-top {
  visibility: visible !important;
  display: flex !important;
}

nav.navbar ul.not-top {
  visibility: hidden;
  display: none !important;
}

nav.navbar .container>ul {
  display: flex;
  justify-content: space-between;
}

nav.navbar .container>ul>li {
  margin: 0 30px;
  padding: 10px;
  font-size: 18px;
}

nav.navbar ul>li>ul {
  display: none;
  transition: 0.3s;
}

nav.navbar.active ul>li.dropdown>ul,
nav.navbar ul>li.dropdown:hover>ul {
  display: block;
}

nav.navbar ul>li>ul>li {
  font-size: 15px;
  margin: 5px 0;
}

nav.navbar-mobile.active {
  height: 100vh;
  background-color: #fff;
}

nav.navbar-mobile ul {
  display: none;
  padding: 0;
  margin: 0;
  list-style-type: none;
}

nav.navbar-mobile.active ul {
  display: block;
}

nav.navbar-mobile ul li {
  margin: 10px 0;
  font-size: 20px;
}

nav.navbar-mobile .burger {
  background-color: transparent;
  border: none;
}

#hero img {
  max-height: 100vh;
  object-fit: cover;
}

#contact,
#signature {
  padding: 50px 0;
  background-color: #f5f5f5;
}

#contact a {
  color: #8d8d8d;
  background-color: #fff;
  padding: 8px 30px;
  border: 1px solid #8d8d8d;
  text-decoration: none;
  transition: 0.3s;
}

#contact a:hover {
  background-color: transparent;
}

#signature .card-header {
  overflow: hidden;
  border-radius: 0;
}

#signature img {
  transition: 0.5s;
}

#signature .card:hover img {
  transform: scale(1.1);
}

#join {
  padding: 50px 0;
}

#join .images {
  padding: 50px 10px;
}

#join input {
  display: block;
  margin-top: 20px;
  padding: 10px;
  width: 100%;
  border-radius: 0;
  border: 1px solid #8d8d8d;
}

#join .big-image {
  position: relative;
}

#join .instagram {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  top: 0;
  transition: 0.3s;
}

#join .instagram h5,
#join .instagram svg,
#join .instagram path {
  color: #fff;
}

#about-us .hero {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

#about-us .hero a {
  color: #8d8d8d;
  transition: 0.3s;
  text-decoration: none;
}

#about-us .hero a:hover {
  color: #8d8d8d;
  cursor: pointer;
}

#about-us .hero h1 {
  font-size: 72px;
}

#about-us #team {
  padding: 50px 0;
}

#about-us .card-dokter {
  width: 100%;
  opacity: 0.85;
  transition: 0.3s;
}

#about-us .card-dokter:hover {
  opacity: 1;
  cursor: pointer;
}

#about-us .modal .modal-content {
  border-radius: 0;
  border: none;
}

#contact-us #header,
#appointment #header {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: start;
}

#contact-us #header .container p {
  width: 40%;
}

#aesthetic .content h1,
#medical .content h1 {
  color: #c15799;
}

.aesthetic-category,
.medical-category {
  position: relative;
}

.aesthetic-category::before,
.medical-category::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}

.aesthetic-category h1,
.medical-category h1 {
  position: relative;
  color: #fff;
}

.page-header {
  position: relative;
  height: 100vh;
}

@media (min-width: 990px) {
  #hero h4 {
    max-width: 35%;
  }

  #fact {
    height: 700px;
  }

  #fact .center {
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
  }

  .aesthetic-category,
  .medical-category {
    background-attachment: fixed;
  }
}

@media (max-width: 990px) {
  #hero {
    margin-top: 75px;
  }

  #fact {
    padding: 50px 0;
  }

  #about-us .hero h1 {
    font-size: 58px;
  }

  #contact-us #header .container {
    position: relative;
  }

  #contact-us #header h1,
  #contact-us #header .container p {
    width: 100%;
    color: #eee;
  }

  .aesthetic-category,
  .medical-category {
    height: 200px !important;
  }

  .personalized *,
  .safety * {
    color: #333 !important;
  }

  .page-header::before,
  #contact-us #header::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
  }

  .page-header .container {
    position: relative;
  }

  .page-header h1,
  .page-header p {
    color: #fff;
  }

  .page-header {
    height: 300px;
    margin-top: 70px;
  }
}